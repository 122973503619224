<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-15 09:09:57
 * @LastEditors: pj
 * @LastEditTime: 2022-11-15 16:48:23
-->
<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-14 14:06:57
 * @LastEditors: pj
 * @LastEditTime: 2022-11-14 17:56:53
-->
<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-07 15:37:39
 * @LastEditors: pj
 * @LastEditTime: 2022-11-11 11:41:38
-->
<template>
  <div class="IdCard">
    <div class="IdCard-top">
      <img src="../../assets/logo.png" />
    </div>
    <div class="IdCard-content">
      <img src="../../assets/downBg.png" alt="" />
      <div class="IdCard-content-work">
        <div class="work-from">
          <div class="work-from-IdCardOne">
            <el-upload
              action="https://api.tanoshi.cc/nft_app/distapi/file/file/uploadImage"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :limit="1"
              :http-request="uploadFile2"
              :before-upload="beforeUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div class="work-from-IdCardTwo">
            <el-upload
              action="https://api.tanoshi.cc/nft_app/distapi/file/file/uploadImage"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :limit="1"
              :http-request="uploadFile"
              :before-upload="beforeUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div class="work-from-list">
            <el-form
              :model="form"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="出生日期" prop="birthDate">
                <el-input v-model="form.birthDate" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="所在区市" prop="address">
                <el-input v-model="form.address" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="width: 100%"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  >立即上传</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getResultOfIdCardApi, userLowAuth } from '@/api/sigh/index.js'
import axios from 'axios'
export default {
  name: 'IdCard',
  components: {},
  data() {
    return {
      form: {
        name: '', //姓名
        birthDate: '',
        address: '', //只做显示作用，地址
        emblemUrl: '', //身份证国徽面
        portraitUrl: '', //身份证人像面
        cardNo: null, //身份证编号
        provinceCode: '', //省编号
        cityCode: '', //市编号
        countyCode: '', //区编号
        provinceName: '', // 省名称
        cityName: '', // 市名称
        countyName: '', // 区名称
      },
      rules: {
        name: [
          { required: true, message: '请上传身份证', trigger: 'blur' },
          { min: 2, max: 8, message: '请上传身份证', trigger: 'blur' },
        ],
        birthDate: [
          { required: true, message: '请上传身份证', trigger: 'blur' },
          { min: 3, max: 99, message: '请上传身份证', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请上传身份证', trigger: 'blur' },
          { min: 3, max: 99, message: '请上传身份证', trigger: 'blur' },
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      //上传后的文件列表
      emblem: '',
      identity: '',
      // 允许的文件类型
      fileType: ['png', 'jpg', 'bmp', 'jpeg'],
      //请求头
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != '' || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 2 //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$message({
            message: '上传文件大小不能超过 2MB!',
            type: 'warning',
          })
          return false
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true
        } else {
          this.$message({
            message: '上传文件格式不正确!',
            type: 'warning',
          })
          return false
        }
      }
    },
    // 国徽
    uploadFile(item) {
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)

      axios({
        method: 'post',
        url: 'https://api.tanoshi.cc/nft_app/api/file/file/uploadImage',
        headers: this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.code === 200) {
          this.emblem = res.data.data
          this.$message({
            message: '上传成功',
            type: 'success',
          })
        }
      })
    },
    // 身份证信息
    uploadFile2(item) {
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      axios({
        method: 'post',
        url: 'https://api.tanoshi.cc/nft_app/api/file/file/uploadImage',
        headers: this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then(async (res) => {
        if (res.data.code === 200) {
          this.identity = res.data.data
          let FormDatas = new FormData()
          FormDatas.append('url', res.data.data)
          const result = await getResultOfIdCardApi(FormDatas)
          if (result.code === 200) {
            this.form = { ...result.data }
            console.log(this.form)
          }
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (this.identity === '' || this.emblem === '') {
          this.$message({
            message: '请上传身份证正反面!',
            type: 'warning',
          })
          return
        }
        if (valid) {
          this.form.emblemUrl = this.emblem
          this.form.portraitUrl = this.identity
          const result = await userLowAuth(this.form)
          this.$message({
            message: '实名认证成功',
            type: 'success',
          })
          sessionStorage.setItem('IdCard', true)
          this.$router.push({ path: '/upload' })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.IdCard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-top {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    image {
      width: 162rpx;
      height: 51rpx;
    }
  }
  &-content {
    width: 100%;
    position: relative;
    &-work {
      width: 100%;
      position: absolute;
      top: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      .work-from {
        width: 880px;
        height: 607px;
        background: #fdfdfd;
        display: flex;
        justify-content: space-around;
        padding-top: 40px;
        flex-wrap: wrap;
        &-IdCardOne {
          /deep/ .el-icon-plus::after {
            content: '上传身份证信息面';
          }
        }
        &-IdCardTwo {
          /deep/ .el-icon-plus::after {
            content: '上传身份证国徽面';
          }
        }

        &-list {
          width: 80%;
          justify-self: center;
        }
      }
    }
  }
}
</style>
