/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-08 15:02:28
 * @LastEditors: pj
 * @LastEditTime: 2022-11-16 14:36:32
 */
import walletApi from "@/api/index";
import request from "@/utils/request";

const header = { "Content-Type": "application/x-www-form-urlencoded" };


export function sendSms (params) {
  return request({
    url: walletApi.sendSms,
    method: "get",
    params: params,
  });
}

export async function sighIn (data) {
  const result = await request({
    url: walletApi.sighIn,
    method: "post",
    data,
  });
  return result;
}

export async function getPublicKey_Api (data) {
  const result = await request({
    url: walletApi.getPublicKey_Api,
    method: "get",
    params: data,
  });
  return result;
}

export async function getpass_Api (data) {
  const result = await request({
    url: walletApi.getpass_Api,
    method: "get",
    params: data,
  });
  return result;
}

export async function sighUp (data) {
  const result = await request({
    url: walletApi.sighUp,
    method: "post",
    data
  });
  return result;
}

export async function getIdentityWord (data) {
  const result = await request({
    url: walletApi.getIdentityWord,
    method: "get",
    params: data,
  });
  return result;
}

export async function getAgreement (data) {
  const result = await request({
    url: walletApi.getAgreement,
    method: "get",
    params: data,
  });
  return result;
}

export async function addImageApi (data) {
  const result = await request({
    url: walletApi.addImageApi,
    method: "post",
    data,
  });
  return result;
}


export async function getResultOfIdCardApi (data) {
  const result = await request({
    url: walletApi.getResultOfIdCardApi,
    method: "post",
    data,
  });
  return result;
}

export async function userLowAuth (data) {
  const result = await request({
    url: walletApi.userLowAuth,
    method: "post",
    data,
  });
  return result;
}


export async function saveOpus (data) {
  const result = await request({
    url: walletApi.saveOpus,
    method: "post",
    data,
  });
  return result;
}


export async function isRelease (data) {
  const result = await request({
    url: walletApi.isRelease,
    method: "post",
    data,
  });
  return result;
}